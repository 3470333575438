import React, { Component } from "react"
import { Link } from 'gatsby'

import "./card.scss"

class Card extends Component {
    render() {
        return (
            <div className={"card-item card-item-" + this.props.cardIcoColor}>
                <div className="card-item__ico">
                    <i className={this.props.cardIco} />
                </div>
                <div className="card-item__content">
                    <h3 className="card-item__title">
                        <Link to={this.props.cardLinkUrl} title={this.props.cardLink}>{this.props.cardLink}</Link>
                    </h3>
                    <p className="card-item__text">{this.props.cardText}</p>
                </div>
            </div>
        )
    }
}

export default Card