import React from "react"

import Card from "../../common/Card/card"

const VideoServices = () => {
    return (
        <section className="container section-service">
            <div className="row space-2-bottom space-3-bottom--lg">
                <div className="col-12">
                    <div className="section-service-title yellow">Servicios de fotografía y vídeo</div>
                    <div className="section-service-subtitle">Contenido multimedia y audiovisual</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-picture"
                        cardIcoColor="yellow"
                        cardLink="Estudio fotográfico"
                        cardLinkUrl="/productora-audiovisual/estudio-fotografico"
                        cardText="Fotografiamos personas, productos y bodegones en un estudio profesional"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-video"
                        cardIcoColor="yellow"
                        cardLink="Reportajes"
                        cardLinkUrl="/productora-audiovisual/reportajes-fotograficos"
                        cardText="Fotografiamos espacios y ambientes destinados al mercado publicitario"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-reportage"
                        cardIcoColor="yellow"
                        cardLink="Vídeos corporativos"
                        cardLinkUrl="/productora-audiovisual/videos-corporativos"
                        cardText="Realización y montaje de vídeos promocionales y corporativos"
                    />
                </div>
            </div>
        </section>
    )
}

export default VideoServices