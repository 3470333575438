import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/common/Layout/layout"
import SEO from "../components/common/SEO/seo"
import BannerPage from "../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../components/section/TextImage/textImage"
import VideoServices from "../components/section/AudiovisualProductionServices/audiovisualProductionServices"
import CallToAction from "../components/section/CallToAction/callToAction"
import { Fragment } from "react"

const VideoPhotoPage = () => {
    const data = useStaticQuery(graphql`
    query {
        sectionImage: file(relativePath: { eq: "images/sections/productora-audiovisual/productora-audiovisual-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Productora Audiovisual"
                description="Nuestra productora audiovisual es experta en la realización de fotografías de productos, reportajes fotográficos y videos corporativos."
            />

            <BannerPage
                title="Producción de fotografía y vídeo"
                text="Realización de contenido multimedia y audiovisual"
                color="yellow"
            />

            <SectionTextImage
                title1="¡El impacto visual"
                title2="de tu empresa!"
                text={<Fragment>
                    Cuidar hasta el último detalle de tu marca es sumamente importante. <strong>Las imágenes o piezas audiovisuales son herramientas clave</strong> para humanizar y demostrar quién eres de una forma más dinámica y activa.<br /><br />
                    <strong>La imagen que muestres, será aquella que te defina y será con la que te valoren</strong>. Es por eso, que cuidarla y mimarla debe de ser una de vuestras principales prioridades. Una buena o mala imagen puede hacer que te escojan a ti o a tu competencia. ¡Todo depende de ti!
                </Fragment>}
                image={data.sectionImage.childImageSharp.fluid}
                imageAlt="Productora Audiovisual Barcelona - Agencia Marketing Digital"
                color="yellow"
                containerTextRight={false}
            />
            <VideoServices />

            <CallToAction
                color="yellow"
                title="¡Creamos contenido multimedia y audiovisual impactante!"
                text={<Fragment>
                    ¡Somos la <strong>productora audiovisual en Barcelona</strong> que estás buscando! <br />
                </Fragment>}
                button="¿Creamos tus piezas audiovisuales?"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default VideoPhotoPage
